<template>
  <div class="achievements--list__container">
    <div class="list xl:w-4/5 lg:w-11/12 mr-auto ml-auto">
      <achievement-each
        v-for="(value, id) in getAchievements"
        :key="id"
        :details="value"
      />
    </div>
  </div>
</template>

<script>
import AchievementEach from "./AchievementEach.vue";
import {
  TrendingUpIcon,
  LayersIcon,
  LayoutIcon,
  DownloadIcon,
  EyeIcon,
} from "vue-feather-icons";

export default {
  name: "AchievementsList",
  components: { AchievementEach },
  computed: {
    getAchievements() {
      return [
        {
          text: "Trending developer on GitHub",
          desc: "On 26 May 2020, I was the trending developer on GitHub. On the same day ytmdl was also trending.",
          icon: TrendingUpIcon,
        },
        {
          text: "API got 105k+ hits within 3 days",
          desc: "Ytmdl Web v2 was shared in places like Reddit and within 3 days it's API recieved 105k+ hits. The post on Reddit and Hackernews went to frontpage.",
          icon: LayersIcon,
        },
        {
          text: "Python app downloaded 110k+ times",
          desc: "ytmdl was downloaded 111,870 times from Python Package Index. It is a tool that helps people extract music with metadata from various sources.",
          icon: DownloadIcon,
        },
        {
          text: "Ytmdl Web has 4.2k+ monthly users",
          desc: "ytmdl web has active users of about 4203 after a month of release. It is a web version of the CLI tool ytmdl.",
          icon: LayoutIcon,
        },
        {
          text: "Webapp got 35k+ pageviews in 3 days",
          desc: "Ytmdl Web V2 recieved 35000 page views within just 3 days of launch. It was shared on Reddit and Hackernews and went to frontpage there.",
          icon: EyeIcon,
        },
      ];
    },
  },
};
</script>
