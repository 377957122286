<template>
  <div class="contact__container bg-cyan-light">
    <div
      class="
        contact--content
        md:py-28
        py-16
        2xl:w-4/5
        lg:w-11/12
        w-11/12
        mr-auto
        ml-auto
        flex flex-wrap
      "
    >
      <div class="left--content md:w-1/2">
        <div class="text">
          <h1
            class="
              md:text-4xl
              text-3xl
              font-merriweather font-extrabold
              md:leading-relaxed
              leading-normal
            "
          >
            Want to work with me on your next awesome project?
          </h1>
        </div>
        <div class="btn mt-16 text-lg">
          <a
            href="mailto:hello@deepjyoti30.dev"
            class="button-base"
            title="Say Hello to me!"
            >Get in touch =></a
          >
        </div>
      </div>
      <div class="right--content md:w-1/2 md:mt-0 mt-32">
        <div class="content md:w-4/5 mr-auto ml-auto">
          <div class="desc text-lg text-gray-800">
            I am always excited to work on something new. Be it a shiny new web
            app or a blazing fast API. Although I do lean towards backend
            mostly. I also love building commandline tools and anything Linux.
          </div>
          <div class="stats mt-20 md:text-lg">
            <icon-text
              v-for="(value, id) in getIconTexts"
              :key="id"
              :data="value"
              class="mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserCheckIcon, PieChartIcon } from "vue-feather-icons";
import IconText from "./IconText.vue";

export default {
  name: "Contact",
  components: { IconText },
  data() {
    return {
      beginExp: 2016,
    };
  },
  methods: {
    findDifferenceInYears: function (begin) {
      /**
       * Find the difference in years in order to update the number
       * of years in experience.
       */
      return new Date().getFullYear() - begin;
    },
    calculateExperience: function () {
      /**
       * Calculate the experience since the beginning to the
       * current year.
       */
      const diff = this.findDifferenceInYears(this.beginExp);

      return `${diff} year${diff > 1 ? "s" : ""}`;
    },
  },
  computed: {
    getExperience() {
      return this.calculateExperience();
    },
    getIconTexts() {
      return [
        {
          icon: UserCheckIcon,
          text: `Over ${this.getExperience} of industry experience`,
        },
        {
          icon: PieChartIcon,
          text: "Always a part of the team",
        },
      ];
    },
  },
};
</script>
