<template>
  <div class="stat--card py-6">
    <h1 class="text-4xl font-bold font-roboto-mono text-teal">
      {{ getNumber }}
    </h1>
    <h2 class="md:text-xl text-lg mt-4 text-gray-800 font-bold uppercase">
      {{ stat.statText }}
    </h2>
    <p class="mt-2 md:text-base text-sm text-gray-600 font-semibold">
      {{ stat.text }}
    </p>
  </div>
</template>

<script>
import { number } from "@/mixins/number";

export default {
  name: "GenericStatCard",
  mixins: [number],
  props: {
    stat: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getNumber() {
      return this.formatNumber(this.stat.number, 0);
    },
  },
};
</script>
