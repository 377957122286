export const animation = {
  methods: {
    isVisible: function (elem) {
      // Check if the passed element is visible.
      // Code from jQuery sources.
      return !!(
        elem.offsetWidth ||
        elem.offsetHeight ||
        elem.getClientRects().length
      );
    },
    animateContent: function (
      classToAnimate = "fade-in-up",
      delayConstant = 0.5
    ) {
      // Animate all the elements with the class
      document.getElementsByClassName(classToAnimate).forEach((el, index) => {
        if (!this.isVisible(el)) return;
        el.style.animationDelay = `${delayConstant + index * 0.1}s`;
      });
    },
  },
};
