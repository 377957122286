<template>
  <div class="stats--each__container md:pr-8">
    <h1 class="md:text-8xl text-6xl font-bold font-roboto-mono text-blue-400">
      {{ getNumber }}
    </h1>
    <p class="mt-3 md:text-2xl text-base text-gray-700 font-bold">
      {{ getText }}
    </p>
  </div>
</template>

<script>
export default {
  name: "StatsCard",
  components: {},
  props: {
    stat: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getText() {
      return this.stat.text;
    },
    getNumber() {
      return this.stat.number;
    },
  },
};
</script>
