<template>
  <div class="achievement--each__container">
    <div class="content border-b border-gray-500 py-4 px-1">
      <div class="top--part flex cursor-pointer" @click="toggleContent">
        <div class="left"><component :is="getIcon" class="mr-6" /></div>
        <div class="right">
          <div class="top--part flex items-center justify-between flex-1">
            <h1 class="md:text-xl font-medium">{{ getText }}</h1>
            <div class="icon">
              <ChevronDownIcon />
            </div>
          </div>
          <div
            :ref="refId"
            :style="[showExtra ? { height: computedHeight } : {}]"
            :class="showExtra ? 'mt-4' : ''"
            class="bottom--part"
          >
            <p>
              {{ getDesc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from "vue-feather-icons";

export default {
  name: "AchievementEach",
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      isExtraVisible: false,
      computedHeight: 0,
      refId: "collpasedContent",
    };
  },
  props: {
    details: {
      type: Object,
      require: true,
    },
  },
  methods: {
    toggleContent: function () {
      /**
       * Show the content when the user clicks on the expand button.
       */
      this.isExtraVisible = !this.isExtraVisible;
    },
    initHeight: function () {
      this.$refs[this.refId].style.height = "auto";

      this.computedHeight = getComputedStyle(this.$refs[this.refId]).height;

      this.$refs[this.refId].style.position = null;
      this.$refs[this.refId].style.height = 0;
    },
  },
  computed: {
    getText() {
      return this.details.text;
    },
    getDesc() {
      return this.details.desc;
    },
    getIcon() {
      return this.details.icon;
    },
    showExtra() {
      return this.isExtraVisible;
    },
  },
  mounted() {
    this.initHeight();
  },
};
</script>

<style lang="scss" scoped>
.bottom--part {
  height: 0;
  overflow: hidden;
  transition: 150ms;
}
</style>
