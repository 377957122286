<template>
  <div class="home">
    <header-about />
    <stats />
    <achievements-peek />
    <works-peek />
    <contact />
    <blog-peak />
    <Footer />
  </div>
</template>

<script>
import HeaderAbout from "@/components/HeaderAbout";
import Stats from "../components/Stats.vue";
import AchievementsPeek from "../components/AchievementsPeek.vue";
import WorksPeek from "../components/WorksPeek.vue";
import Contact from "../components/Contact.vue";
import BlogPeak from "../components/BlogPeak.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    HeaderAbout,
    Stats,
    AchievementsPeek,
    WorksPeek,
    Contact,
    BlogPeak,
    Footer,
  },
};
</script>
