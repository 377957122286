<template>
  <div class="works--peek__container">
    <div class="works--peek--content md:my-40 my-28 flex flex-wrap-reverse">
      <div class="md:w-1/2">
        <div class="left--wrapper md:w-3/5 w-10/12 mr-auto ml-auto">
          <work-list :projects="getProjects" />
        </div>
      </div>
      <div class="right--content md:w-1/2 md:mb-0 mb-28">
        <div class="right--wrapper xl:w-3/5 w-11/12 mr-auto ml-auto">
          <h1
            class="
              md:text-4xl
              text-3xl
              font-merriweather font-extrabold
              md:leading-relaxed
              leading-normal
            "
          >
            Some of my best works as a Full Stack Developer.
          </h1>
          <p class="mt-8 text-lg">
            I really appreciate Open Source and almost all of my work are open
            source. I make sure that I always find a way to give back to the
            community.
          </p>
          <div class="btn--container mt-16">
            <div>
              <router-link
                to="/works"
                class="button-base"
                title="Check all my works"
                >Check my work</router-link
              >
            </div>
            <div class="mt-14">
              <router-link
                to="/uses"
                class="button-outline"
                title="Things I use"
                >Check what I use</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkList from "./WorkList.vue";
import projectList from "@/static/projects.js";

export default {
  components: { WorkList },
  name: "WorksPeek",
  data() {
    return {
      topWorks: this.findTopWorks(),
    };
  },
  methods: {
    findTopWorks() {
      /**
       * Find the top 3 works and return that accordingly.
       *
       * Since this component only needs to show the best work,
       * there is no need to show all of them or show in sequence.
       */
      const topWorkIds = ["ytmdl-web", "ytmdl-repo", "downloader-cli-repo"];
      return projectList.filter((project) => topWorkIds.includes(project.id));
    },
  },
  computed: {
    getProjects() {
      return this.topWorks;
    },
  },
};
</script>
