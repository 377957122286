<template>
  <div class="header--about__container">
    <div
      class="
        wrapper
        2xl:w-2/5
        xl:w-1/2
        lg:w-3/5
        w-11/12
        mr-auto
        ml-auto
        mt-16
        text-center
      "
    >
      <h1
        class="
          md:text-6xl
          text-4xl
          font-merriweather font-black
          md:leading-relaxed
          leading-normal
        "
      >
        I build Useful, Creative and Confident apps.
      </h1>
      <p class="mt-14 md:text-2xl text-lg text-gray-700">
        I build production level API's and integrate them with awesome webapps.
        I build libraries, mostly in Python. I also build commandline utilities
        with the aim of making peoples lives easier.
      </p>
      <div
        class="
          button--container
          mt-16
          flex flex-wrap
          justify-center
          items-center
          md:text-xl
        "
      >
        <router-link to="/works" class="button-base" title="Check my Works"
          >Check my work</router-link
        >
        <a
          href="https://github.com/deepjyoti30"
          target="_blank"
          rel="noopener noreferrer"
          class="button-outline md:ml-8 ml-0 md:mt-0 mt-8"
          title="Check my Github Profile"
          >Check github =></a
        >
      </div>
    </div>
    <div class="language-particles">
      <img
        v-for="(value, id) in getIcons"
        :key="id"
        :src="require(`@/assets/media/${value}.png`)"
        :class="value"
        class="fade-in-up"
        :alt="`${value} logo`"
        :title="`${value[0].toUpperCase() + value.slice(1)}`"
      />
    </div>
  </div>
</template>

<script>
import { animation } from "@/mixins/animation.js";

export default {
  name: "HeaderAbout",
  data() {
    return {
      icons: ["python", "docker", "git", "js", "vue", "postgresql", "mongo"],
    };
  },
  mixins: [animation],
  methods: {},
  computed: {
    getIcons() {
      return this.icons;
    },
  },
  mounted() {
    this.animateContent("fade-in-up", 0.2);
  },
};
</script>

<style lang="scss" scoped>
.header--about__container {
  position: relative;

  .language-particles {
    img {
      width: 4rem;
      position: absolute;
    }

    .python {
      top: 5%;
      left: 20%;
    }

    .js {
      top: 10%;
      right: 20%;
    }

    .vue {
      top: 50%;
      left: 15%;
    }

    .git {
      top: 55%;
      right: 22%;
    }

    .docker {
      bottom: -10%;
      left: 15%;
    }

    .postgresql {
      bottom: -12%;
      right: 15%;
    }

    .mongo {
      bottom: -35%;
      left: 25%;
    }

    @media only screen and (max-width: 1280px) {
      .python {
        left: 15%;
      }

      .vue {
        left: 10%;
      }

      .js {
        right: 15%;
      }

      .git {
        right: 11%;
      }
    }

    @media only screen and (max-width: 1024px) {
      img {
        width: 3rem;
      }

      .python {
        left: 5%;
        top: -5%;
      }

      .vue {
        top: 35%;
      }

      .docker {
        left: 5%;
      }

      .mongo {
        bottom: -45%;
        left: 20%;
      }

      .postgresql {
        bottom: -35%;
      }

      .git {
        top: 85%;
      }

      .js {
        top: -10%;
        right: 5%;
      }
    }

    @media only screen and (max-width: 768px) {
      img {
        width: 2rem;
        display: none;
      }

      .git {
        right: 2%;
      }

      .js {
        top: 0%;
      }

      .vue {
        top: 32%;
        left: 15%;
      }

      .docker {
        bottom: 25%;
      }

      .mongo {
        bottom: -15%;
      }

      .postgresql {
        bottom: -20%;
      }
    }
  }
}
</style>
