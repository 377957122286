<template>
  <div class="icon--text__container">
    <div class="icon--text--content flex items-center">
      <component :is="data.icon" size="1.3x" />
      <div class="text ml-4 font-medium">
        {{ data.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconText",
  props: {
    data: {
      type: Object,
      require: true,
    },
  },
};
</script>
