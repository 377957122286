<template>
  <div class="blog--peak__container">
    <div
      class="
        blog--peak--content
        md:my-40
        my-28
        xl:w-auto
        w-11/12
        mr-auto
        ml-auto
        flex flex-wrap-reverse
      "
    >
      <div class="left--content md:w-1/2 md:mt-0 mt-28">
        <div
          v-if="arePostsFetched"
          class="blog--peak 2xl:w-2/3 xl:w-11/12 mr-auto ml-auto"
        >
          <blog-each
            v-for="(value, id) in fetchedPosts"
            :key="id"
            :blog="value"
            class="mb-4"
          />
        </div>
      </div>
      <div class="right--content md:w-1/2">
        <div class="content md:w-4/5 mr-auto ml-auto">
          <h1
            class="
              md:text-4xl
              text-3xl
              font-extrabold font-merriweather
              md:leading-relaxed
              leading-normal
            "
          >
            I occasionally write about Tech, Mechanical Keyboards and Linux.
          </h1>
          <div class="btn mt-16">
            <a
              href="https://blog.deepjyoti30.dev"
              class="button-outline text-lg"
              target="_blank"
              rel="noopener noreferrer"
              >Check my blog =></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogEach from "./BlogEach.vue";
export default {
  components: { BlogEach },
  name: "BlogPeak",
  data: function () {
    return {
      blogEndpoint: "https://apis.deepjyoti30.dev/blog/posts",
      arePostsFetched: false,
      fetchedPosts: [],
    };
  },
  methods: {
    fetchBlogPosts: async function () {
      /**
       * Fetch the blog posts by using the API
       */
      const response = await fetch(this.blogEndpoint);

      const json = await response.json();

      this.arePostsFetched = true;
      this.fetchedPosts = json.slice(0, 4);
    },
  },
  mounted() {
    this.fetchBlogPosts();
  },
};
</script>
