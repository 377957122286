<template>
  <div class="achievements--peak__container md:py-40 py-24 bg-black text-white">
    <div
      class="
        achievements--content
        xl:w-4/5
        lg:w-11/12
        w-11/12
        mr-auto
        ml-auto
        flex flex-wrap
      "
    >
      <div
        class="left--content md:w-1/2 md:mb-0 mb-36 md:text-left text-center"
      >
        <h1
          class="
            md:text-4xl
            text-3xl
            font-merriweather font-bold
            md:leading-relaxed
            leading-normal
          "
        >
          Things I achieved while creating and building over the last 5+ years.
        </h1>
        <div class="button mt-16">
          <router-link
            to="/achievements"
            class="button-base"
            title="Check all my achievements"
            >Check achievements</router-link
          >
        </div>
      </div>
      <div class="right--content md:w-1/2">
        <achievements-list />
      </div>
    </div>
  </div>
</template>

<script>
import AchievementsList from "./AchievementsList.vue";
export default {
  components: { AchievementsList },
  name: "AchievementsPeak",
};
</script>
