<template>
  <div class="blog--each__container">
    <div class="blog--each--content border-b-2 py-2">
      <div class="title--date flex flex-wrap justify-between items-center">
        <div class="title md:w-auto w-full">
          <a
            :href="getLink"
            class="font-medium md:text-xl link-highlight"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ getTitle }} =>
          </a>
        </div>
        <h4 class="mt-2 text-gray-600 md:text-base text-sm font-medium">
          {{ getDate }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogEach",
  props: {
    blog: {
      type: Object,
      require: true,
    },
  },
  computed: {
    getTitle() {
      return this.blog.title;
    },
    getLink() {
      return `https://blog.deepjyoti30.dev/${this.blog.slug}`;
    },
    getDate() {
      return new Date(this.blog.updated_at).toLocaleString("en-US", {
        dateStyle: "medium",
      });
    },
  },
};
</script>
