<template>
  <div class="stats__container mt-32">
    <div
      class="
        stats--content
        md:pt-60
        pt-12
        md:pb-48
        pb-16
        2xl:w-4/5
        xl:w-11/12
        lg:w-11/12
        w-11/12
        mr-auto
        ml-auto
        flex flex-wrap
      "
    >
      <div class="md:w-1/2">
        <h1
          class="
            md:text-4xl
            text-3xl
            font-merriweather font-bold
            md:leading-relaxed
            leading-normal
            md:text-left
            text-center
            mt-4
          "
        >
          I like to let my
          <span>numbers speak for themselves</span>
        </h1>
        <div
          class="numbers md:mt-16 mt-10 flex flex-wrap md:text-left text-center"
        >
          <stats-card
            v-for="(percentStat, id) in getPercentageStat"
            :key="id"
            :stat="percentStat"
            class="xl:w-1/2 w-11/12 xl:mt-0 mt-12 mr-auto ml-auto"
          />
        </div>
      </div>
      <div class="md:w-1/2 md:mt-0 mt-28">
        <div class="wrapper md:w-3/5 w-full mr-auto ml-auto">
          <generic-stat-card
            v-for="(stat, id) in getStat"
            :key="id"
            :stat="stat"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from "./PercenStatCard.vue";
import GenericStatCard from "@/components/StatCard.vue";

export default {
  components: { StatsCard, GenericStatCard },
  name: "Stats",
  computed: {
    getStat() {
      return [
        {
          number: 617657,
          statText: "Lines of code",
          text: "Lines of frontend & backend code in various languages including Python and JS.",
        },
        {
          number: 452407,
          statText: "API Hits",
          text: "Hits on various API's built with Python/FastAPI/Flask and throttled with Kong.",
        },
        {
          number: 169654,
          statText: "Webapps used",
          text: "Pageviews on web apps built with VueJS/HTML/Scss deployed in containers.",
        },
      ];
    },
    getPercentageStat() {
      return [
        {
          number: "600%",
          text: "Request spikes in 2 days handled gracefully",
        },
        {
          number: "99.8%",
          text: "Successful responses from the API",
        },
      ];
    },
  },
};
</script>
